.messages-main-container{
    display: flex;
    height: 100%;
}

.messages-main-container .topics{
    border-right: 4px solid #efefef;
    min-width: 20%;
    max-width: 200px;
}

.messages-main-container .NewTopic{
    text-align: center;
}

.messages-main-container .topics .Topic{
    margin: .25rem;
    border-bottom: 2px solid #efefef;
    padding: 1rem;
    display: flex;
    justify-content: space-between;
}

.messages-main-container .NewTopic button{
    margin: .75rem;
}

.messages-main-container .messages{
    flex-grow: 1;
}

.message-card{
    padding: .125rem 0;
    margin: 0 .75rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ced4da;
}

.message-card .content{
    padding-left: 0.85rem;
    width: 100%;
}

.message-card form{
    display: flex;
    align-items: flex-start;
    width: 100%;
}

.message-card .Spinner{
    padding-right: .75rem;
    padding-top: .625rem;
    padding-bottom: .625rem;
}

.message-card .buttons{
    display: flex;
}

.new-message form{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 1rem;
}

.new-message .creating-content{
    padding-left: 1rem;
    padding-right: 1rem;
}

.new-message .Spinner{
    padding-right: 1rem;
}

.messages-container .topic-name{
    font-size: 1.5rem;
    padding: 1rem;
}

.topics .Topic .topic-name{
    cursor: pointer;
    flex-grow: 1;
}

.topics .Topic .topic-controls{
    display: flex;
}

.topics .Topic .topic-controls .icon{
    cursor: pointer;
    margin-left: 10px;
}