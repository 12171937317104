.App{
  display: flex;
}

.App nav {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  padding-top: 5rem;
  background-color: #303030;
}

.App nav div{
  padding: .5rem;
  margin-bottom: 1rem;
}

.App nav a{
  color: white;
}

.App .page-container{
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    max-height: 100vh;
}

.App .page-content{
  overflow-y: auto;
  height: 100%;
}

.Header{
  background-color: #efefef;
  color: #424242;
  padding: 1rem;
  height: 4rem;
}

.Header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 1.5rem;
    font-weight: 500;
}

.Header-login{
    display: inline-block;
}

.Header-email{
    font-size: 1rem;
}

.NotAuthenticated{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 80vh;
}

.NotAuthenticated .content{
  font-size: 1.5rem;
  padding: 0 1rem;
  padding-bottom: 1.5rem;
  text-align: center;
}

.full-width{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.width-100{
  width: 100%;
}

.Footer{
  text-align: center;
  color: #a9a9a9;
  position: fixed;
  bottom: 0;
  width: 100%;
}

.validation-error{
  position: absolute;
  bottom: -24px;
  padding-left: 1rem;
}

.data-section{
  padding: 2rem;
}

.data-section .section-header{
  font-size: 1.5rem;
  font-weight:600;
  padding-bottom: 1rem;
}

.Spinner{
  line-height: 0;
  display: inline-block;
}

.section-header .Spinner{
  margin-left: 0.5rem;
}

.big-spinner{
  text-align: center;
  padding: 2rem 0;
}

.mid-spinner{
  text-align: center;
  padding: 1rem 0;
}

.Spinner .spinner-border{
  font-size: 2px;
  width: 18px;
  height: 18px;
}

.spinner-text{
  color: #818385;
  text-align: center;
  font-style: italic;
  padding-top: 1rem;
}

.big-spinner .spinner-border{
  width: 8rem;
  height: 8rem;
}

.mid-spinner .spinner-border{
  width: 4rem;
  height: 4rem;
}

.fade-in-1{
  animation: fadein 1s;
}

.fade-in-0-5{
  animation: fadein .5s;
}

.cur-def{
  cursor: default;
}

@keyframes fadein{
  from {opacity: 0;}
  to {opacity: 1;}
}

button.w-spinner{
  display: flex;
  align-items: center;
}
button.w-spinner .Spinner{
  margin-left: 8px;
} 

.icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 0.7rem;
}

.custom-error{
  color: var(--bs-red);
  font-size: 1.75rem;
  text-align: center;
  padding: 1rem;
}
.no-border{
  border: none;
}

.btn.icon-btn:hover, .btn.icon-btn:focus, .btn.icon-btn:active{
  color:var(--bs-gray);
  box-shadow: 0 0px 3px 2px rgba(0, 0, 0, 0.4);
}

.btn.icon-btn.green:hover, .btn.icon-btn.green:focus, .btn.icon-btn.green:active{
  color:var(--bs-green);
  box-shadow: 0 0px 3px 2px rgba(0, 135, 0, 0.4);
}

.btn.icon-btn.red:hover, .btn.icon-btn.red:focus, .btn.icon-btn.red:active{
  color:var(--bs-red);
  box-shadow: 0 0px 3px 2px rgba(135, 0, 0, 0.4);
}

.no-caret.dropdown-toggle::after{
  display: none;
}

.pointer{
  cursor: pointer;
}

@media only screen and (max-width: 768px) {
  .Header-email{
    font-size: 0.75rem;
  }
  .section-header{
    font-size: 1rem;
  }
  .custom-error{
    font-size: 1rem;
  }

  .data-section{
    padding: 1rem;
  }

  .data-section .section-header{
    font-size: 1rem;
    padding-bottom: 1rem;
  }

  .App nav svg{
    font-size: 1.5rem;
  }

  .App nav div{
    padding: .25rem;
    margin-bottom: 1rem;
  }
}

.remove-triangle.dropdown-toggle::after {
  display: none !important; 
}