.Login{
    text-align: center;
}

.Login{
    height: 100vh;
    padding-top: 7.5rem;
}

.Login form{
    padding-top: 3rem;
    margin: 0 auto;
}

.Login .login-card{
    border: 1px solid lightgray;
    max-width: 480px;
    margin: 0 auto;
    padding: 3rem;
    border-radius: .5rem;
    box-shadow: 2px 2px 2px lightgray;
}

@media only screen and (max-width: 768px) {

    .Login{
        height: 100vh;
        padding-top: 0;
    }

    .Login form{
        padding: 3rem 1rem;
    }

    .Login .login-card{
        border: none;
        max-width: 100%;
        padding: 3rem 0;
        box-shadow: none;
    }

}