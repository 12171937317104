.DayHeader{
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1.5rem;
    font-weight:600;
}

.activity-card{
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ced4da;
    padding: .125rem 0;
}

.activity-card .content{
    padding-left: 0.85rem;
    width: 100%;
}

.activity-card form{
    display: flex;
    align-items: flex-start;
    width: 100%;
}

.activity-card .buttons{
    display: flex;
}

.new-activity form{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 1rem 0;
}

.new-activity .creating-content{
    padding-left: 1rem;
    padding-right: 1rem;
}

.new-activity .Spinner{
    padding-right: 1rem;
}

.activity-status-container{
    width: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.activity-card .editing {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.activity-card .editing .Spinner{
    padding-right: .75rem;
    padding-top: .625rem;
    padding-bottom: .625rem;
}

.activity-card .editing .content{
    padding-left: 52px;
}

.activity-card .editing form{
    margin-left: 40px;
}


.empty-activities{
    color: var(--bs-gray);
    font-size: 0.9rem;
}

.PreviousDayoffs, .DayoffDetails{
    background-color: #4a4a4a;
    color: white;
}

.PreviousDayoff{
    margin-bottom: 1rem;   
}

.PreviousTask{
    margin-left: .75rem;
    margin-bottom: .5rem;
}

.PreviousTask .content{
    padding-left: 1.25rem
}

.gray-border-bottom{
    border: none;
    border-bottom: 1px solid #ebebeb;
}

.w2-25{
    width: 2.25rem;
}

.DayoffDetails{
    padding: 2rem;
}

@media only screen and (max-width: 768px) {
    .DayHeader{
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 1rem;
    }
}