.Dayoffs{
    padding: 1rem;
}

.Dayoff{
    margin-bottom: 1.5rem;
}

.NewDayoff{
    margin-bottom: 2rem;
}

.NewDayoff .general-container{
    padding: .5rem;
}

.NewDayoff .general-container input{
    margin-left: 1rem;
}

.card-container{
    border: 1px solid lightgray;
    padding: 1rem;
    border-radius: .5rem;
}

.Dayoff .header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 3px solid #ebebeb;
}

.Dayoff .header .date{
    color: gray;
}